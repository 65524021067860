<template>
    <div class="hero">
        <div class="description">

            <h3>{{ content.title }}</h3>

            <p>{{ content.description }}</p>

            <button class="left-aligned-button">{{ content.ctaText }}</button>

        </div>

        <div class="image-container">

            <img :src="content.imageUrl" alt="Hero Image" />

        </div>


        <!-- <img src="https://picsum.photos/200/300" alt="Example Image" /> -->

    </div>
</template>

<script>
    import { reactive } from 'vue'
    // import axios from '@/utils/axios/axios.js';


    export default {
        // props: {
        //     content: Object,
        // },



        setup() {

            const imageUrl = require("@/assets/images/stock/thinkiqTechnology.jpg");
            const content = reactive({
                title: "Your Partner in Technology",
                description: "Strategize. Design. Build. Integrate. Enable. Analyze. Sustain. We partner with you to solve your complex business problems through technology.'",
                ctaText: "Learn more",
                imageUrl,

            })

            // const content = ref([])

            // const getContent = async () => {
            //     try {
            //         const response = await axios.get("/homeview/");
            //         content.value = response.data;
            //     }
            //     catch (error) {
            //         console.error(error);
            //     }
            // }
            return {
                content,

                // getContent,



            }
        }
    };
</script>

<style scoped>
    .hero {
        /* Styles for the hero section */
    }
</style>
