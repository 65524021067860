<template>
    <footer>
        <div class="copyright">
            <p>&copy; {{ currentYear }} ThinkIQ Business Solutions</p>
        </div>
        <div class="socials">
            <i class="bi bi-instagram"></i>
            <i class="bi bi-facebook"></i>
            <i class="bi bi-linkedin"></i>
        </div>
        <div class="quick-menus">
            <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">About us</a></li>
                <li><a href="#">Resources</a></li>
                <li><a href="#">Support</a></li>
            </ul>
        </div>
    </footer>

</template>

<script>
export default {
    name: 'FooterPage',
    setup() {

        const currentYear = new Date().getFullYear();

        return {
            currentYear,
        };
    }
};
</script>

<style scoped></style>