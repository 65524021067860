
import image1 from '@/assets/stock/roadmap.jpeg';
import image2 from '@/assets/stock/7118756_3426526.jpeg';
import image3 from '@/assets/stock/digital marketing.jpeg';



export const achievementsData = [
  {
    id: 1,
    title: 'Loan Management System Development',
    description: 'Successfully developed and implemented a Loan Management System, streamlining loan processes and improving client servicing.',
    date: '2024-04-10',
    category: 'Financial Systems',
    points: 200,
    images: [
      image1,image2,image3,image1,image2
    ],
  },
  {
    id: 2,
    title: 'CRM and CMS for Organization',
    description: 'Built and deployed a CRM and CMS tailored for organizational needs, enhancing customer relationship management and content management efficiency.',
    date: '2024-03-05',
    category: 'Customer and Content Management',
    points: 220,
    images: [
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
    ],
  },
  {
    id: 3,
    title: 'Customizable Web Platform Development',
    description: 'Designed and developed a customizable web platform for the organization, enabling flexible and scalable online presence.',
    date: '2024-02-20',
    category: 'Web Development',
    points: 210,
    images: [
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
    ],
  },
  {
    id: 4,
    title: 'Property Management System for BEVC',
    description: 'Developed a comprehensive Property Management System for BEVC, improving property tracking and management.',
    date: '2024-01-30',
    category: 'Property Management',
    points: 230,
    images: [
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
    ],
  },
  {
    id: 5,
    title: 'Document Management Integration',
    description: 'Integrated advanced document management capabilities into existing systems, enhancing document handling and storage.',
    date: '2023-12-15',
    category: 'Document Management',
    points: 180,
    images: [
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
      `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`,
    ],
  },
];
