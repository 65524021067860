<template>
  <LayoutFile />>
</template>

<script>
import LayoutFile from './components/LayoutFile.vue';

export default {
  components: {
    LayoutFile,
  }
}


</script>

<style>

  /* Importing fonts from Google Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap');

  /* Base font settings */
  body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
  }

  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  
  }

  /* Paragraphs and text */
  p,
  span,
  a,
  li {
    font-family: 'Roboto', sans-serif;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;




  }


</style>
