import SoftwareProductImage from '@/assets/stock/roadmap.jpeg';
import softwareServicesImage from '@/assets/stock/7118756_3426526.jpeg';
import DigitalMarketigImage
  from '@/assets/stock/digital marketing.jpeg';

export const Solutions = [
  {
    id: 1,
    title: 'Software Product Management',
    description: "Efficiently navigate the complex landscape of software development with our expert product management solutions. We help you define, plan, and execute your software product strategy, ensuring alignment with your business goals, user needs, and market trends. From concept to delivery, we'll guide you through every stage of the product lifecycle, optimizing resources and maximizing your product's success.",
    imageUrl: SoftwareProductImage,
  },
  {
    id: 2,
    title: 'Software Development',
    description: "Transform your ideas into powerful, custom software solutions. Our experienced development team specializes in crafting robust, scalable, and user-friendly applications tailored to your specific requirements. Whether it's web, mobile, or desktop development, we leverage the latest technologies and best practices to deliver high-quality software that drives innovation and competitiveness in your industry.",
    imageUrl: softwareServicesImage,
  },
  {
    id: 3,
    title: 'Digital Marketing',
    description: "Elevate your online presence and achieve digital success with our comprehensive digital marketing services. We employ a data-driven approach to strategize, implement, and optimize your digital campaigns across various platforms. From search engine optimization (SEO) and pay-per-click (PPC) advertising to content marketing and social media management, we'll boost your brand visibility, engage your audience, and drive measurable results to fuel your business growth.",
    imageUrl: DigitalMarketigImage,
  },
];
